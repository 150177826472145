.dependent-card-custom {
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  overflow: hidden;
  margin-bottom: 16px;
  display: flex;
  /* border-left: 4px solid; */
  position: relative;
}

.card-content-custom {
  flex: 1 1;
  padding: 10px 15px;
}

.card-header-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-title-custom {
  font-size: 16px;
  font-weight: 600;
  color: #2d3748;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.request-type-custom {
  padding: 6px 12px;
  /* border-radius: 4px; */
  font-size: 13px;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.05);
  position: absolute;
  right: 0px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.repo-loki-dependent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.info-grid-custom {
  background: #f9f9f9;
  border-radius: 6px;
  padding: 8px;
  margin-top: 10px;
}

.info-row-custom {
  display: flex;
  justify-content: space-between;
}

.info-item-custom {
  flex: 1;
  padding: 2px 6px;
}

.repo-loki-dependent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}
.repo-cover-remark-loki p {
  font-size: 14px;
}
.repo-cover-remark-loki span {
  font-size: 15px;
  font-weight: 500;
}
.repo-cover-remark-loki {
  word-break: break-all;
  margin-top: 10px;
}
.info-value-custom {
  font-size: 14px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 5px;
}

p.info-label-custom {
  margin-bottom: 0px;
  font-size: 13px;
}

.separator-custom {
  height: 1px;
  background: rgba(255, 177, 37, 0.5);
  margin: 8px 0;
}